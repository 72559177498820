<template>
  <div class="edit-email-template-content-container col-8">
    <CCard>
      <CCardHeader>
        <h1>{{$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_TITLE')}}</h1>
      </CCardHeader>
      <CCardBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <CForm @submit.prevent="handleSubmit(onSubmit)">
            <CTabs variant="tabs" :active-tab="0">
              <CTab :title="$t('EDIT_EMAIL_TEMPLATE_PAGE_TAB_EN_LABEL_TITLE')">
                <div @change="isFormEN=true">
                  <CRow>
                    <CCol>
                      <ValidationProvider
                        name="EDIT_EMAIL_TEMPLATE_PAGE_FIELD_EN_SUBJECT"
                        v-slot="{ errors }"
                        :rules="isFormEN ? 'required|max:1000' : ''"
                      >
                        <CInput
                          :label="$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_EN_SUBJECT')"
                          v-model="english.subject"
                        />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <ValidationProvider
                        name="EDIT_EMAIL_TEMPLATE_PAGE_FIELD_EN_BODY"
                        v-slot="{ errors }"
                        :rules="isFormEN ? 'required' : ''"
                      >
                        <div role="group" class="form-group">
                          <label class>{{$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_EN_BODY')}}</label>
                        </div>
                        <yimo-vue-editor v-model="english.template"></yimo-vue-editor>

                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </div>
              </CTab>
              <CTab :title="$t('EDIT_EMAIL_TEMPLATE_PAGE_TAB_JP_LABEL_TITLE')">
                <div @change="isFormJP=true">
                  <CRow>
                    <CCol>
                      <ValidationProvider
                        name="EDIT_EMAIL_TEMPLATE_PAGE_FIELD_JP_SUBJECT"
                        v-slot="{ errors }"
                        :rules="isFormJP ? 'required|max:1000' : ''"
                      >
                        <CInput
                          :label="$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_JP_SUBJECT')"
                          v-model="japan.subject"
                        />
                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <ValidationProvider
                        name="EDIT_EMAIL_TEMPLATE_PAGE_FILED_JP_BODY"
                        v-slot="{ errors }"
                        :rules="isFormJP ? 'required' : ''"
                      >
                        <div role="group" class="form-group">
                          <label class>{{$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_JP_BODY')}}</label>
                        </div>
                        <yimo-vue-editor v-model="japan.template"></yimo-vue-editor>

                        <span class="error-msg-wrap">
                          <span class="error-msg">{{errors[0]}}</span>
                        </span>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </div>
              </CTab>
            </CTabs>
            <CRow>
              <CCol>
                <CButton
                  class="btn-save"
                  @click="onSubmit"
                >{{$t('EDIT_EMAIL_TEMPLATE_PAGE_BUTTON')}}</CButton>
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { Constants } from '@/constants';
import YimoVueEditor from 'yimo-vue-editor';

export default {
  name: 'EditEmailTemplate',
  components: {
    YimoVueEditor,
  },
  data() {
    return {
      listOption: [],
      name: '',
      group_name: '',
      email: [],
      english: {},
      japan: {},
      isFormEN: false,
      isFormJP: false,
    };
  },
  async mounted() {
    this.name = this.$route.params.name;
    await this.getEmailDetail();
    this.getOptionTemplate();
  },
  methods: {
    async getOptionTemplate() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getOptionTemplate, { params });
        this.listOption = result.data;
        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_EMAIL_TEMPLATE_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getEmailDetail() {
      try {
        const res = await this.$http.get(endpoints.getEmailDetail(this.name));
        if (res.data) {
          const items = res.data;
          this.english = items.find(data => data.language === 'en') || this.getDefault('en');
          this.japan = items.find(data => data.language === 'ja') || this.getDefault('ja');
          this.email.push(this.english, this.japan);
          this.group_name = items[0].group_name;
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_EMAIL_TEMPLATE_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    getDefault(value) {
      switch (value) {
        case 'en':
          return {
            name: this.name,
            subject: null,
            template: null,
            language: 'en',
          };

        case 'ja': {
          return {
            name: this.name,
            subject: null,
            template: null,
            language: 'ja',
          };
        }
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.update();
      });
    },
    async update() {
      try {
        const items = this.email
          .filter(el => el.subject !== null)
          .map(el => {
            return {
              name: el.name,
              subject: el.subject,
              template: el.template,
              language: el.language,
            };
          });
        const data = {
          email_templates: items,
        };

        const result = await this.$http.put(endpoints.updateEmailTemplate, data);
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('EDIT_EMAIL_TEMPLATE_PAGE_NOTIFY_TITLE_SUCCESSFUL'),
          text: this.$t('EDIT_EMAIL_TEMPLATE_PAGE_NOTIFY_TEXT_SUCCESSFUL'),
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_EMAIL_TEMPLATE_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.edit-email-template-content-container {
  padding: 0 15px;
  h1 {
    color: #657187;
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 0px;
  }
  .tab-content {
    padding: 30px 10px 0 10px;
  }
  .btn-save {
    background: #3b4b7e;
    color: #fff;
    padding: 5px 40px;
    margin-top: 30px;
  }
  textarea {
    &.form-control {
      height: 400px;
    }
  }
  .group_name {
    font-weight: bold;
    display: block;
    margin: 0 0 10px 0;
  }
}
</style>
