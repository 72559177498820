var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-email-template-content-container col-8"},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_TITLE')))])]),_c('CCardBody',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',{attrs:{"title":_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_TAB_EN_LABEL_TITLE')}},[_c('div',{on:{"change":function($event){_vm.isFormEN=true}}},[_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"EDIT_EMAIL_TEMPLATE_PAGE_FIELD_EN_SUBJECT","rules":_vm.isFormEN ? 'required|max:1000' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_EN_SUBJECT')},model:{value:(_vm.english.subject),callback:function ($$v) {_vm.$set(_vm.english, "subject", $$v)},expression:"english.subject"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"EDIT_EMAIL_TEMPLATE_PAGE_FIELD_EN_BODY","rules":_vm.isFormEN ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v(_vm._s(_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_EN_BODY')))])]),_c('yimo-vue-editor',{model:{value:(_vm.english.template),callback:function ($$v) {_vm.$set(_vm.english, "template", $$v)},expression:"english.template"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1)]),_c('CTab',{attrs:{"title":_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_TAB_JP_LABEL_TITLE')}},[_c('div',{on:{"change":function($event){_vm.isFormJP=true}}},[_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"EDIT_EMAIL_TEMPLATE_PAGE_FIELD_JP_SUBJECT","rules":_vm.isFormJP ? 'required|max:1000' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('CInput',{attrs:{"label":_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_JP_SUBJECT')},model:{value:(_vm.japan.subject),callback:function ($$v) {_vm.$set(_vm.japan, "subject", $$v)},expression:"japan.subject"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('CRow',[_c('CCol',[_c('ValidationProvider',{attrs:{"name":"EDIT_EMAIL_TEMPLATE_PAGE_FILED_JP_BODY","rules":_vm.isFormJP ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',{},[_vm._v(_vm._s(_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_LABEL_JP_BODY')))])]),_c('yimo-vue-editor',{model:{value:(_vm.japan.template),callback:function ($$v) {_vm.$set(_vm.japan, "template", $$v)},expression:"japan.template"}}),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1)])],1),_c('CRow',[_c('CCol',[_c('CButton',{staticClass:"btn-save",on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('EDIT_EMAIL_TEMPLATE_PAGE_BUTTON')))])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }